import { toast } from 'react-toastify';
import queryString from 'query-string';
import React from 'react';

let toastActive = null;

export const noop = () => {};

export const getHostnameFromString = (data) => {
  const a = document.createElement('a');
  a.href = data;

  if (!data.startsWith('http://') && !data.startsWith('https://')) {
    return data;
  }

  if (a.hostname.startsWith('www.')) {
    a.hostname = a.hostname.slice(5);
  }

  return a.hostname;
};

/**
 * Get params from link
 * @returns {any | {}}
 */
export const getParamsFromLink = (location = {}) => {
  const params = queryString.parse(location.search) || {};

  const transformedParams = Object.keys(params).reduce((acc, prop) => {
    if (params[prop] === 'true') {
      acc[prop] = true;
    } else if (params[prop] === 'false') {
      acc[prop] = false;
    }
    return acc;
  }, {});

  return transformedParams;
};

/**
 * Get domain from subdomain
 * @returns
 */
export const getDomainName = () => {
  if (typeof window !== 'undefined') {
    let domain = window.location.hostname.replace(/([^/:]+):\/\/([^/]+):([0-9]+)\//, '$1://$2/').split('.');

    if (domain.length) {
      domain = `.${domain[domain.length - 2]}.${domain[domain.length - 1]}`;
      return domain;
    }

    return window.location.hostname;
  }
  return null;
};

/**
 * Get user name
 * @param {*} user
 * @returns
 */
export const getUserName = (user = {}) => {
  let name = '';

  if (user.first_name && user.last_name) {
    name = `${user.first_name} ${user.last_name}`;
  } else if (user.first_name && !user.last_name) {
    name = `${user.first_name}`;
  } else if (!user.first_name && !user.last_name) {
    name = `${user.email}`;
  }

  return name;
};

/**
 * Join received by emails
 * Used in notice page
 * @param {*} user
 * @returns
 */
export const getReceivedByList = (received_by = {}) => Object.keys(received_by)
  .map((key) => received_by[key].email.toLowerCase())
  .join(', ');

/**
 * Translate text
 * @param {*} value
 * @param {*} params
 */
export const translate = (value = '', params = {}) => {
  let text = value;

  Object.keys(params).forEach((prop) => {
    text = text.replace(`%${prop}%`, params[prop]);
  });

  return text;
};

/**
 * Show error
 * @param {*} error
 * @param {*} tosterOptions
 */
export const showError = (errorType, tosterOptions = {}, errorHtml = null) => {
  let toaster = null;
  function Msg({ closeToast }) {
    if (errorHtml) {
      return <div dangerouslySetInnerHTML={{ __html: errorHtml }} />;
    }
    return (
      <div>
        It looks like the page that you requested does
        not exist or you don&apos;t have permission to view it. Please
        {' '}
        <a href="mailto:support@dmcanow.io">
          <strong>contact us</strong>
        </a>
        {' '}
        if you think this should not happen.
      </div>
    );
  }

  if (!toastActive) {
    toaster = toast.error(Msg, {
      position: toast.POSITION.BOTTOM_RIGHT,
      hideProgressBar: true,
      autoClose: 6000,
      ...tosterOptions,
      onClose: () => {
        toastActive = null;
      },
    });

    toastActive = toaster;
  }

  return toaster;
};

/**
 * Get domain stasuses
 * @param {*} order
 */
export const getDomainStatuses = (order) => {
  const status = order.status || {};
  const isRegistred = status.id === 13;
  const isRemoved = status.id === 14;
  const isDraft = status.id === 10;

  return {
    isRegistred,
    isRemoved,
    isDraft,
  };
};

/**
 * Get stasuses
 * @param {*} order
 */
export const getOrderStatuses = (order) => {
  const isAmendmentOrder = order.type && order.type.id === 3;
  const status = order.status || {};
  const isWaitForPayment = status.id === 9;
  const isProcessing = status.id === 8;
  const isComplete = status.id === 7;
  const isDraft = status.id === 10;

  return {
    isAmendmentOrder,
    isWaitForPayment,
    isProcessing,
    isComplete,
    isDraft,
  };
};

/**
 * Get api url
 */
export const getApiUrl = () => {
  const { href } = window.location;

  if (href.includes('dmcanow')) {
    if (href.includes('sb')) {
      return 'https://sbapi.dmcanow.io';
    }
    return 'https://api.dmcanow.io';
  }
  return process.env.REACT_APP_API_URL;
};

/**
 * Get ws url
 * @returns
 */
export const getWsUrl = () => {
  const { href } = window.location;

  if (href.includes('dmcanow')) {
    if (href.includes('sb')) {
      return 'wss://ws.sb.dmcanow.io/ws';
    }
    return 'wss://ws.dmcanow.io/ws';
  }

  return process.env.REACT_APP_WS_URL;
};

/**
 * Collect error response text
 * @param {*} res
 */
export const collectErrorMessage = (res) => {
  let result = '';

  if (Array.isArray(res.data.errors)) {
    [result] = res.data.errors;
  }

  if (res.status === 500) {
    result = 'Was some thing bad with server try again!';
  }

  return result;
};

export const isDev = () => {
  let isDev = false;

  if (typeof window !== 'undefined') {
    if (window.location.origin.indexOf('test-mydmca.now') > -1 || window.location.origin.indexOf('localhost') > -1) {
      isDev = true;
    }
  }

  return isDev;
};

/**
 * Get subscription types
 */
export const getSubscriptionTypes = () => {
  let planId = 'dmca-agent-year';

  if (typeof window !== 'undefined') {
    if (window.location.origin.indexOf('dmcanow') > -1) {
      planId = 'dmca-agent-year-221';
    }
  }

  return {
    yearPlanId: planId,
  };
};

/**
 * Get lanfing page url
 * @returns
 */
export const getLandingUrl = () => {
  const { href } = window.location;

  if (href.includes('dmcanow')) {
    if (href.includes('sb')) {
      return 'https://sb.dmcanow.io';
    }
    return 'https://dmcanow.io';
  }
  return 'http://127.0.0.1';
};

/**
 * Get landing host
 * @returns
 */
export const getLandingHost = () => {
  const url = getLandingUrl();
  if (!url) return '';
  return url.replace(/(^\w+:|^)\/\//, '');
};

/**
 * Get url params
 * @param {*} input
 */
export const getUrlParams = (input = '') => {
  const result = {};

  if (input) {
    const params = input.replace('?', '').split('&');

    params.map((item) => {
      const [key, value] = item.split('=');

      result[key] = value;
      return item;
    });
  }
  return result;
};

/**
 * Collect error response text
 * @param {*} res
 */
export const collectDevMessagesErrorMessage = (res) => {
  let result = '';

  if (res && !Array.isArray(res.data) && Array.isArray(res.data.errors)) {
    res.data.errors.map((item) => {
      if (Array.isArray(item)) {
        item.map((item2) => {
          result += `${item2} \n`;
          return item2;
        });
      } else {
        result += `${item} \n`;
      }

      return item;
    });
  } else if (res && Array.isArray(res.data)) {
    res.data.map((item) => {
      if (Array.isArray(item)) {
        item.map((item2) => {
          result += `${item2} \n`;
          return item2;
        });
      } else {
        result += `${item} \n`;
      }

      return item;
    });
  }

  if (res.status === 500) {
    result = 'Was some thing bad with server try again!';
  } else if (res.status === 401) {
    result = 'User isn\'t logged in';
  } else if (res.status === 403) {
    result = "User hasn't permissions to view/edit/delete object";
  } else if (res.status === 404) {
    result = "Object isn't exist";
  } else if (res.status === 405) {
    result = 'If front end code use wrong HTTP method for API request';
  }

  return `${result} [Error ${res.status}]`;
};

// eslint-disable-next-line no-use-before-define
export const filter = (inputValue = '', data) => data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

/**
 * Scroll to top
 */
export const scrollToTop = () => {
  if (window) {
    window.scrollTo(0, 0);
  }
};

/**
 * Modal Scroll to top
 */
export const modalScrollToTop = (el) => {
  const modalContainer = !el ? document.querySelector('.react-modal-overlay') : el;

  if (modalContainer) {
    modalContainer.scrollTo(0, 0);
  }
};

/**
 * On select dropdown close
 */
export const closeDropdown = (elem) => {
  if (elem && typeof elem.blur === 'function') {
    setTimeout(() => {
      if(elem !== null) {
        elem.blur();
      }
    }, 0);
  }
};

/**
 *
 * @param {*} name
 * @param {*} value
 */
export const setCookie = (name, value) => {
  const expires = "; expires=Fri, 31 Dec 9999 21:10:10 GMT";
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

/**
 *
 * @param {*} name
 * @returns
 */
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export const deleteCookie = (name) => {
  document.cookie =  name + '=; expires=Thu, 18 Dec 2013 12:00:00 UTC'
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Detect card type
 * @param {*} number
 */
export const GetCardType = (number) => {
  // Visa
  const visaRegex = /^4/;
  if (visaRegex.test(number)) {
    return 'visa';
  }

  // Mastercard
  const mastercardRegex = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;
  if (mastercardRegex.test(number)) {
    return 'mastercard';
  }

  // AMEX
  const amexRegex = /^3[47]/;
  if (amexRegex.test(number)) {
    return 'amex';
  }

  // Discover
  const discoverRegex = /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/;
  if (discoverRegex.test(number)) {
    return 'discover';
  }

  // Diners
  const dinersRegex = /^36/;
  if (dinersRegex.test(number)) {
    return 'diners';
  }

  // Diners - Carte Blanche
  const dinersClubRegex = /^30[0-5]/;
  if (dinersClubRegex.test(number)) {
    return 'diners_club';
  }

  // JCB
  const jcbRegex = /^35(2[89]|[3-8][0-9])/;
  if (jcbRegex.test(number)) {
    return 'jcb';
  }

  // Visa Electron
  const visaElectronRegex = /^(4026|417500|4508|4844|491(3|7))/;
  if (visaElectronRegex.test(number)) {
    return 'visa_electron';
  }

  return 'visa';
};
